.overview-container {
  padding: 30px;
  /* Add some padding to the container */
}

.overview-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.cards-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.card {
  background-color: var(--secondary-white);
  /* Use theme color for background */
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 30px;
  /* Increase padding for more breathing room */
  flex: 0 0 calc(33% - 40px);
  /* Adjust width as needed */
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  transition: all 0.3s ease;
  /* Add smooth transitions */
}

.card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-3px);
  /* Add a subtle lift on hover */
}

.card h3 {
  margin-bottom: 15px;
  color: var(--primary-blue);
  font-size: 1.5rem;
  /* Increase heading size */
}

.card p {
  color: var(--primary-blue);
  /* Use theme color for text */
  line-height: 1.6;
  /* Improve readability */
}