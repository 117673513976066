.api-keys-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: var(--secondary-white);
  border-radius: 8px;
  max-width: 600px;
  margin: 0 auto;
}

.title {
  font-size: 1.5em;
  margin-bottom: 20px;
  color: var(--primary-blue);
}

.api-key-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  /* Space between details */
  align-items: center;
  /* Align items vertically centered */
}

.api-key-info {
  display: flex;
  flex-direction: row;
  gap: 20px;
  /* Space between details */
  width: 100%;
  justify-content: space-around;
}

.api-key-detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.9em;
}

.api-key-detail span {
  margin: 5px 0;
  /* Space between detail entries */
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.spinner {
  border: 4px solid var(--input-border);
  border-top: 4px solid var(--button-blue);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.success {
  color: var(--success-green);
  font-size: 1em;
}

.error {
  color: red;
  font-size: 1em;
  background-color: var(--error-background);
  border: var(--error-border);
  text-align: center;
  padding: 7px;
  font-weight: 500;
}

.notice {
  position: relative;
  /* Ensure the pseudo-element is positioned relative to the .notice container */
  background-color: #f9f2f4;
  padding: 18px;
  margin-bottom: 15px;
  color: #c0392b;
  font-size: 14px;
  border: 1px solid #e74c3c;
}