.usage-statistics {
    margin: 20px;
    font-family: Arial, sans-serif;
    text-align: center;
}

.usage-title-header {
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
}

.usage-title-header h2 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.filters {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.filters select, .filters button {
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #bdc3c7;
    background-color: #ecf0f1;
    color: #2c3e50;
    outline: none;
}

.filters select:focus {
    border-color: #3498db;
    box-shadow: 0 0 5px rgba(52, 152, 219, 0.5);
}

.filters button {
    background-color: #3498db;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.filters button:hover {
    background-color: #2980b9;
}

.filters select option {
    background-color: white;
    color: #2c3e50;
}

.loading {
    text-align: center;
    font-size: 18px;
    color: #3498db;
}

.spinner {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid var(--primary-blue); /* Dark blue from your theme */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin: 20px auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
