.dashboard-container {
    display: flex;
    height: 100vh;
  }
  
  .sidebar {
    width: 250px;
    background-color: var(--primary-blue);
    color: var(--secondary-white);
    padding: 20px;
  }
  
  .sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .sidebar li {
    margin-bottom: 15px;
    font-size: 1.1rem;
  }
  
  .sidebar a { /* Style the links */
    color: var(--secondary-white);
    text-decoration: none;
  }
  
  .sidebar a:hover {
    text-decoration: underline;
  }
  
  .main-content {
    flex: 1;
    padding: 30px;
    overflow: auto;
    background-color: var(--secondary-white);
  }