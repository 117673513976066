.generate-api-key {
    background-color: var(--secondary-white);
    border: 1px solid var(--input-border);
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.section-title {
    font-size: 1.2em;
    color: var(--primary-blue);
    margin-bottom: 10px;
}

.api-key-name-input {
    width: calc(100% - 22px);
    padding: 10px;
    border: 1px solid var(--input-border);
    border-radius: 4px;
    margin-right: 10px;
    font-size: 1em;
    box-sizing: border-box;
}

.generate-key-btn {
    background-color: var(--primary-blue);
    border: none;
    color: white;
    padding: 10px 15px;
    border-radius: 4px;
    min-width: 130px;
    cursor: pointer;
    font-size: 1em;
}

.generate-key-input-wrapper {
    display: flex;
}

.generate-key-btn:disabled {
    background-color: var(--button-hover);
    cursor: not-allowed;
}

.generate-key-error {
    color: white;
    margin-top: 10px;
    background-color: rgb(214, 112, 112);
    padding: 7px;
    border-radius: 4px;
}

.generate-key-btn-spinner {
    border: 4px solid var(--input-border);
    border-top: 4px solid var(--button-blue);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 0.6s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}