.request-info {
    background-color: var(--secondary-white); /* Use your theme's light gray background */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 300px; /* Keep the component compact */
    margin-left: 45px;
    transform: translateY(38%);
}

.request-info h4 {
    color: var(--primary-blue); /* Use your theme's dark blue for headings */
    margin: 5px 0;
    font-weight: 600;
}

.request-info h4:first-of-type {
    font-size: 1.2em; /* Slightly larger for the total requests */
}

.request-info h4:last-of-type {
    font-size: 1em;
    color: var(--input-border-focus); /* Add a different color for variation */
}
