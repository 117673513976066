/* src/components/CookieConsentBanner.css */
.cookie-consent-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #222;
    color: #fff;
    padding: 15px;
    text-align: center;
    z-index: 1000;
}

.cookie-consent-banner button {
    margin: 5px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    color: #fff;
}

.cookie-consent-banner button:first-of-type {
    background-color: #4CAF50; /* Accept button */
}

.cookie-consent-banner button:last-of-type {
    background-color: #f44336; /* Reject button */
}
