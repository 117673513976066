/* Container Styling */
.privacy-policy__container {
    width: 100%;
    max-width: 900px;
    margin: 70px auto;
    padding: 40px;
    background-color: var(--secondary-white);
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    font-family: 'Roboto', sans-serif;
}

/* Header Styling */
.privacy-policy__header {
    text-align: center;
    margin-bottom: 30px;
}

.privacy-policy__title {
    font-size: 2.5rem;
    font-weight: 600;
    color: #333;
}

.privacy-policy__effective-date {
    font-size: 1rem;
    color: #777;
}

/* Section and Text Styling */
.privacy-policy__intro {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #555;
    text-align: center;
    margin-bottom: 40px;
}

.privacy-policy__section {
    margin-bottom: 30px;
}

.privacy-policy__section-title {
    font-size: 1.75rem;
    font-weight: 500;
    color: var(--primary-blue);
    margin-bottom: 15px;
}

.privacy-policy__text {
    font-size: 1.1rem;
    line-height: 1.7;
    color: #333;
    margin-bottom: 20px;
}

/* List Styling */
.privacy-policy__list {
    padding-left: 20px;
}

.privacy-policy__list-item {
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 10px;
}

/* Link Styling */
.privacy-policy__link {
    color: #4a90e2;
    font-weight: 500;
    text-decoration: none;
}

.privacy-policy__link:hover {
    text-decoration: underline;
}

.privacy-policy__container {
    transition: all 0.3s ease-in-out;
}