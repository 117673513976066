/* ApiKeyPopup.css */

.api-key-popup {
  transform: translate(0px, -90%);
  background-color: var(--secondary-white);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  border: 4px solid var(--primary-blue);
}

.api-key-popup h3 {
  color: var(--primary-blue);
  margin-bottom: 15px;
}

.api-key-popup p {
  margin-bottom: 10px;
}

.api-key-popup input[type="text"] {
  flex: 1;
  padding: 8px;
  border: 1px solid var(--input-border);
  border-radius: 3px;
  margin-right: 10px;
}

.api-key-popup button {
  padding: 8px 12px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.api-key-popup button:first-of-type {
  /* Target the "Copy" button */
  background-color: var(--button-blue);
  color: white;
}

.api-key-popup button:last-of-type {
  /* Target the "Close" button */
  background-color: var(--primary-blue);
  color: white;
}