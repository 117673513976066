/* Spinner.css */
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1); /* Light border */
    border-left-color: var(--primary-blue); /* Darker or your theme's primary color */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
