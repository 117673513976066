/* src/components/Layout.css */
.layout-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    border-top: 1px solid white;
  }
  
  .layout-main {
    flex: 1;
  }
  
  .footer {
    background-color: var(--primary-blue);
    color: var(--secondary-white);
    text-align: center;
    padding: 1rem;
    border-top: 1px solid white;
  }
  
  .footer-nav {
    margin-top: 0.5rem;
    display: flex;
    justify-content: center;
    gap: 7px;
  }
  
  .footer-link {
    color: var(--secondary-white);
    text-decoration: none;
  }
  
  .footer-link:hover {
    text-decoration: underline;
  }
  