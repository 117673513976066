.contact-page__container {
    width: 100%;
    max-width: 600px;
    padding: 50px;
    background: linear-gradient(180deg, #2c3e50 0%, #34495e 50%, #4a90d6 100%);
    background-size: 100% 200%;
    background-position: top;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 50%;
    color: white;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: gradientAnimation 10s ease-in-out infinite;
  }
  
  /* The keyframes remain the same for the gradient animation */
  @keyframes gradientAnimation {
    0% {
      background-position: top;
    }
    50% {
      background-position: bottom;
    }
    100% {
      background-position: top;
    }
  }
  
.contact-page__title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
}

.contact-page__form-group {
    margin-bottom: 15px;
}

.contact-page__label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.contact-page__input,
.contact-page__textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--input-border);
    border-radius: 5px;
    font-size: 1rem;
}

.contact-page__submit-button {
    width: 100%;
    padding: 10px;
    background-color: var(--primary-blue);
    color: white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    border: 2px solid var(--input-border);
    transition: ease-in-out 0.4s;
}

.contact-page__submit-button:hover {
    background-color: var(--input-border);
    color: var(--primary-blue);
    border-radius: 5px;
}

.contact-page__submit-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.contact-page__error-message {
    color: red;
    margin-top: 10px;
}

.contact-page__success-message {
    text-align: center;
    font-size: 1.2rem;
    color: var(--primary-blue);
    background-color: var(--success-background);
    padding: 8px;
    border-radius: 5px;
    font-weight: 600;
}
