.header {
  background-color: var(--primary-blue);
  padding: 1rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  /* Fixes the header at the top */
  top: 0;
  /* Aligns the header to the top of the viewport */
  left: 0;
  /* Aligns the header to the left of the viewport */
  width: 100%;
  /* Makes the header span the full width of the viewport */
  z-index: 1000;
  /* Ensures the header stays above other content */
  border-bottom: 1px solid white;
  height: 66px;
  align-content: center;
}

body {
  padding-top: 65px;
}

.nav-list {
  list-style: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.nav-list li {
  margin: 0 15px;
}

.nav-list li a {
  text-decoration: none;
  color: var(--secondary-white);
  font-weight: bold;
  transition: 0.2s ease-in;
}

.nav-list li a:hover {
  color: var(--primary-blue);
  text-shadow: 
  -0.5px -0.5px 0px white, /* top-left */
  0.5px -0.5px 0px white,  /* top-right */
  -0.5px 0.5px 0px white,  /* bottom-left */
  0.5px 0.5px 0px white;   /* bottom-right */
  transition: 0.2s ease-in;
}

.welcome-message {
  font-size: 16px;
  font-weight: 500;
  margin-left: 15px;
  color: var(--secondary-white);
}

.logout-btn {
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  background-color: var(--primary-blue);
  border: none;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.2s;
  border: 1px solid white;
}

.logout-btn:hover {
  background-color: var(--input-border);
  transform: scale(1.05);
  color: black;
}

.logout-btn:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--button-blue);
}