:root {
  --primary-blue: #2c3e50;
  /* Dark Blue */
  --secondary-white: #ecf0f1;
  /* Light Gray */
  --code-background: #2c3e50;
  /* Dark background for code blocks */
  --code-border: #1c1c1c;
  /* Slightly lighter border for code blocks */
  --highlight-color: #3498db;
  /* Highlight color */
  --text-color: #ecf0f1;
  /* Light color for text */
  --border-radius: 12px;
  --padding: 20px;
  --margin: 20px;
  --font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.api-docs-container {
  background-color: var(--secondary-white);
  color: var(--primary-blue);
  padding: var(--padding);
  border-radius: var(--border-radius);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 900px;
  margin: var(--margin) auto;
  font-family: var(--font-family);
  /* border: 8px solid var(--primary-blue); */
}

.api-docs-header {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: var(--primary-blue);
  border-bottom: 3px solid var(--highlight-color);
  padding-bottom: 0.5rem;
}

.api-docs-section {
  margin-bottom: 2rem;
}

.api-docs-section h2 {
  font-size: 1.75rem;
  margin-bottom: 0.75rem;
  color: var(--primary-blue);
  border-bottom: 2px solid var(--highlight-color);
  padding-bottom: 0.5rem;
}

.api-docs-section h3 {
  font-size: 1.5rem;
  margin-top: 1rem;
  color: var(--highlight-color);
  border-bottom: 1px solid var(--highlight-color);
  padding-bottom: 0.5rem;
}

.api-docs-paragraph {
  margin-bottom: 1rem;
  line-height: 1.6;
}

.api-docs-list {
  list-style-type: disc;
  margin-left: 20px;
}

.api-docs-list li {
  margin-bottom: 0.5rem;
}

.api-docs-code-block {
  background-color: var(--code-background);
  border: 1px solid var(--code-border);
  border-radius: var(--border-radius);
  padding: var(--padding);
  overflow-x: auto;
  margin-top: 0.5rem;
  font-family: monospace;
  color: var(--text-color);
}

.api-docs-code {
  color: var(--highlight-color);
}

.api-docs-example {
  font-size: 0.9rem;
  margin-top: 1rem;
  background-color: var(--code-background);
  padding: var(--padding);
  border-radius: var(--border-radius);
  color: var(--text-color);
}