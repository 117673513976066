.terms-of-service-container {
    max-width: 900px;
    margin: 70px auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
    background-color: var(--secondary-white);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .terms-of-service-container h1,
  .terms-of-service-container h2,
  .terms-of-service-container h3 {
    color: var(--primary-blue);
    margin-top: 20px;
  }
  
  .terms-of-service-container h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
  }
  
  .terms-of-service-container h2 {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .terms-of-service-container h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  
  .terms-of-service-container p {
    line-height: 1.8;
    margin-bottom: 15px;
  }
  
  .terms-of-service-container ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 15px;
  }
  
  .terms-of-service-container a {
    color: var(--button-blue);
    text-decoration: none;
    font-weight: bold;
  }
  
  .terms-of-service-container a:hover {
    text-decoration: underline;
  }
  