:root {
    --primary-blue: #2c3e50;
    --secondary-white: #ecf0f1;
    --button-blue: #3498db;
    --button-hover: #2980b9;
    --input-border: #bdc3c7;
    --input-border-focus: #3498db;
    --success-green: #2ecc71; 
    --success-green-light: #e9f5e9; 
    --error-background: #f9f2f4;
    --error-border: 1px solid #e74c3c;
    --success-background: #99d29b;
}
  
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Helvetica Neue', sans-serif;
  }
  
  .auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: var(--secondary-white);
  }
  
  .auth-box {
    background-color: var(--primary-blue);
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
  }
  
  .auth-box h2 {
    color: var(--secondary-white);
    margin-bottom: 30px;
    font-size: 24px;
  }
  
  .auth-form .input-group {
    margin-bottom: 20px;
  }
  
  .auth-form input {
    width: 100%;
    padding: 12px 15px;
    border: 1px solid var(--input-border);
    border-radius: 5px;
    font-size: 16px;
    outline: none;
  }
  
  .auth-form input:focus {
    border-color: var(--input-border-focus);
  }
  
  .submit-btn {
    background-color: var(--primary-blue);
    color: var(--secondary-white);
    border: 1px solid var(--input-border);
    padding: 12px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
    font-weight: 600;
  }
  
  .submit-btn:hover {
    background-color: var(--input-border);
    color: black;
  }
  
  @media (max-width: 768px) {
    .auth-box {
      padding: 30px;
    }
  }

  .consent-warning {
    margin-top: 20px;
    background-color: orange;
    padding: 7px 0;
    border-radius: 4px;
  }

  .success-verify-email-msg {
    margin-bottom: 20px;
    background-color: var(--success-background);
    color: var(--primary-blue);
    padding: 4px;
    border-radius: 4px;
    font-weight: 600;
  }