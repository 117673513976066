.sandbox-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    background-color: var(--secondary-white);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 40px;
}

.input-group {
    margin-bottom: 15px;
}

.input-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.input-group input,
.input-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--input-border);
    border-radius: 4px;
    box-sizing: border-box;
}

button:hover {
    background-color: var(--button-hover);
}

.flashcard-container {
    margin-top: 20px;
    padding: 20px;
    background-color: var(--secondary-white);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.flashcard {
    font-size: 18px;
    margin-bottom: 15px;
    height: 66px;
}

.sandbox-container  button {
    background-color: var(--primary-blue);
    color: var(--secondary-white);
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
    width: 145px;
}

.error {
    color: red;
    margin-top: 15px;
}
