.profile-main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: var(--secondary-white);
}

.profile-card {
    background-color: var(--primary-blue);
    /* Dark Blue */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%;
    max-width: 500px;
    box-sizing: border-box;
    color: var(--secondary-white);
    /* Text color for dark background */
}

.profile-header {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.profile-message {
    margin: 10px 0;
    padding: 10px;
    border-radius: 4px;
    font-size: 0.9rem;
}

.profile-error {
    color: #e74c3c;
    /* red */
    background-color: #fce4e4;
    /* light red */
}

.profile-success {
    color: var(--secondary-white);
    font-size: 18px;
    font-weight: 900;
    text-align: center;
    background-color: var(--success-green);
}

.profile-form-group {
    margin-bottom: 15px;
}

.profile-form-group label {
    display: block;
    font-size: 1rem;
    margin-bottom: 5px;
}

.profile-form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--input-border);
    border-radius: 4px;
    font-size: 1rem;
    box-sizing: border-box;
    background-color: #fff;
    /* White background for inputs */
}

.profile-form-group input:focus {
    border-color: var(--input-border-focus);
    outline: none;
}

.profile-button-group {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.profile-button {
    padding: 10px 15px;
    border-radius: 4px;
    border: 1px solid white;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.4s ease;
}

.profile-save-button {
    background-color: var(--button-blue);
    color: var(--secondary-white);
}

.profile-save-button:hover {
    background-color: var(--button-hover);
}

.profile-cancel-button {
    background-color: #e74c3c;
    /* red */
    color: var(--secondary-white);
}

.profile-cancel-button:hover {
    background-color: #c0392b;
    /* darker red */
}

.profile-edit-button {
    background-color: var(--primary-blue);
    color: var(--secondary-white);
}

.profile-edit-button:hover {
    background-color: var(--input-border);
    color: black;
    transition: background-color 0.4s ease;
}