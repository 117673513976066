.homepage-container {
  font-family: Arial, sans-serif;
}

.homepage-header {
  background: linear-gradient(180deg, #2c3e50 0%, #34495e 50%, #4a90d6 100%);
  background-size: 100% 200%;
  background-position: top;
  padding: 100px 0;
  position: relative;
  animation: gradientAnimation 10s ease-in-out infinite;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes gradientAnimation {
  0% {
    background-position: top;
  }
  50% {
    background-position: bottom;
  }
  100% {
    background-position: top;
  }
}


.homepage-header-content {
  /* Semi-transparent overlay */
  padding: 20px;
  text-align: center;
  color: var(--secondary-white);
}

.homepage-title {
  font-size: 3rem;
  color: var(--secondary-white);
  margin: 0;
}

.homepage-subtitle {
  font-size: 1.5rem;
  margin-top: 10px;
}

.homepage-main {
  padding: 50px;
  text-align: center;
}

.homepage-features-title {
  font-size: 2rem;
  margin-bottom: 20px;
}

.homepage-features-list {
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
}

.feature-item {
  background-color: var(--secondary-white);
  padding: 20px;
  border-radius: 8px;
  width: 200px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
}

.feature-item:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.feature-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.homepage-cta {
  margin: auto;
  width: 51%;
  margin-top: 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 25px 0;
  background-color: var(--secondary-white);
  border-radius: 8px;
}

.homepage-cta-title {
  font-size: 2rem;
  margin-bottom: 10px;
}

.homepage-cta-text {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.homepage-cta-button {
  background-color: var(--secondary-white);
  color: var(--primary-blue);
  padding: 10px 20px;
  border: 2px solid var(--primary-blue);
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  transition: background-color 0.3s, transform 0.3s;
}

.homepage-cta-button:hover {
  background-color: var(--primary-blue);
  color: white;
}

@media (max-width: 768px) {

  .homepage-header-content,
  .homepage-main {
    padding: 20px;
  }

  .homepage-title {
    font-size: 2rem;
  }

  .homepage-features-list {
    flex-direction: column;
    gap: 20px;
  }
}
